import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { graphql, withPrefix } from "gatsby";
import Helmet from "react-helmet";
import Layout from "../components/layout";
import styled from "styled-components";
import C from "../../colors";
import SEO from '../components/seo'
import LocalizedLink from "../components/localizedLink";
import { FormattedMessage } from "react-intl";

const BlogPage = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px 0;

  .hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }

  @media (min-width: 850px) {
    .content-wrap {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      max-width: 75% !important;
    }
  }

  .content-wrap {
    position: relative;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  @media (min-width: 1100px) {
    .content-wrap,
    .site-footer {
      margin-left: 0;
      margin-right: auto;
    }
  }

  @media (min-width: 700px) {
    .content-wrap {
      -webkit-transition: margin-right, margin-left 0.45s ease 75ms;
      transition: margin-right, margin-left 0.45s ease 75ms;
      margin-right: 0;
    }
  }

  .blogtitle {
    color: ${C.dark};
    font-size: 2.2rem;
    margin-top: 10px;
    padding-bottom: 8px;
    @media (max-width: 600px) {
      font-size: 2rem;
      line-height: 1.6;
      padding: 0px;
      margin-bottom: 0px;
      margin-top: 0px;
    }
  }

  /* pagination */

  .paginationList {
    display: flex;
    padding-left: 0;
    margin-top: 10px;
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: 20px;
    margin-left: 0;
    list-style: none;
    @media (max-width: 600px) {
      font-size: 16px;
      line-height: 1.6;
      padding-top: 40px;
    }

    a {
      padding: 0.5em;
      margin-right: 0.3em;
      color: ${C.blue};
      font-size: 17px;

      &:hover {
        color: ${C.gray2};
      }
    }
  }

  /* pagination */

  .md {
    font-size: 17px;
    line-height: 1.75;
    color: ${C.dark};
    h1,
    h2,
    h3 {
      color: ${C.darker};
    }
    h1 {
      margin-bottom: 30px;
    }
    img {
      display: block;
      max-width: 100%;
      margin: 20px auto;
    }
    @media (max-width: 600px) {
      font-size: 16px;
      line-height: 1.6;
    }
  }
  .post-link {
    list-style: none;
    margin: 20px 0 50px;
    display: flex;
    .post-thumb {
    }
    .post-title {
    }

    a {
      font-size: 28px;
      color: ${C.darker};
    }
    img {
      float: left;
      max-width: 120px;
      margin-right: 20px;
      // margin-top: 10px;
      border-radius: 3px;
      cursor: pointer;
    }
    @media (max-width: 600px) {
      margin: 32px 0;
      img {
        max-width: 80px;
        margin-right: 15px;
        margin-top: 10px;
      }
      a {
        font-size: 150%;
      }
    }
  }
`;

const renderPagination = (currentPage, numPages) => {
  var rows = [];
  for (var i = 1; i <= numPages; i++) {
    rows.push(
      <PaginationItem disabled={i === currentPage} key={i}>
        <PaginationLink
          href={i === 1 ? withPrefix("/blog/") : withPrefix(`/blog/${i}/`)}
        >
          {i}
        </PaginationLink>
      </PaginationItem>
    );
  }
  return <Pagination aria-label="Blog navigation">{rows}</Pagination>;
};

const PostLink = props => (
  <section className="post-link" key={props.key}>
    {props.featuredImage.file.url && (
      <div className="post-thumb">
        <LocalizedLink to={`/info/${props.slug}`}>
          <img alt={props.title} src={props.featuredImage.file.url} />
        </LocalizedLink>
      </div>
    )}
    <div className="post-title">
      <LocalizedLink to={`/info/${props.slug}`}>{props.title}</LocalizedLink> <br />
      <span className="date">{props.date}</span>
    </div>
  </section>
);

const IndexPage = ({ data, pageContext }) => {
  let DATAPOST = data.posts.edges;
  let DATAIMG = data.fallbackImage.edges;


  //creating fallBack array that checks if images are missing in the localized content and fixes it 
  let DATAQL = new Array();
  for (let i=0; i< DATAPOST.length ; i++ ){
    let node = new Object;
    node['slug']=DATAPOST[i].node.slug; 
    node['title'] = DATAPOST[i].node.title
    node['date'] = DATAPOST[i].node.date
    node['node_locale'] = DATAPOST[i].node.node_locale
    node['contentful_id'] = DATAPOST[i].node.contentful_id

    if (DATAPOST[i].node.featuredImage.file !== null ) {
      node['featuredImage'] = DATAPOST[i].node.featuredImage
    } else {
      for (let j=0; j < DATAIMG.length; j++){
        if (DATAPOST[i].node.contentful_id === DATAIMG[j].node.contentful_id) {
          node['featuredImage'] = DATAIMG[j].node.featuredImage
        }
      }
    }

    DATAQL.push(node);
  }

  let url = `https://www.eosgo.io/info/`;
  let seoTitle 
  let seoDescription

  const { currentPage, numPages, locale } = pageContext;
  if (locale === "en-US") {
  if (currentPage === 1) {
    seoTitle = "All you need to know about EOS | EOS Go General Info"
    seoDescription = "EOS Go General Info is a page that focuses on all the fundamentals about the most widely used blockchain in crypto, EOS."
  } else {
    seoTitle = `All you need to know about EOS | EOS Go General Info page ${currentPage}`
    seoDescription = `This is a page that focuses on all the fundamentals about the most widely used blockchain in crypto, EOS. | EOS Go General Info page ${currentPage}`
  }
} else if (locale === "zh-CN") {
  if (currentPage === 1) {
    seoTitle = "这里有你想知道EOS 的一切 | EOS Go初学者小白手册"
    seoDescription = "EOS Go 初学者是一个针对小白的学堂，着重介绍有关EOS区块链的所有基础知识，是进入EOS 的第一步。通过这个页面你可以入门区块链，可谓EOS区块链学堂，EOS区块链小白快速入门，掌握关键方法,理解去中心化和分布式存储概念，学习被大多数人看好的EOS的原因，以及与EOS相关的各种知识，秒懂区块链。"
  } else {
    seoTitle = `这里有你想知道EOS 的一切 | EOS Go初学者小白手册 第${currentPage}页`
    seoDescription = `EOS Go 初学者是一个针对小白的学堂，着重介绍有关EOS区块链的所有基础知识，是进入EOS 的第一步。通过这个页面你可以入门区块链，可谓EOS区块链学堂，EOS区块链小白快速入门，掌握关键方法,理解去中心化和分布式存储概念，学习被大多数人看好的EOS的原因，以及与EOS相关的各种知识，秒懂区块链。第${currentPage}页`
  }
}

  return (
    <Layout title="Blog" locale={locale}>
      <SEO 
        title = {seoTitle}
        description = {seoDescription}
        />
      <BlogPage>
        <h1 className="blogtitle"> <FormattedMessage id="info-title" /></h1>
        <br />
        {DATAQL.map((p, i) => {
          if (p != null) {
            return <PostLink {...p} key={i} />;
          }
        })}

        {numPages > 1 && renderPagination(currentPage, numPages)}
      </BlogPage>
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query InfoPostsPageQueries($skip: Int!, $limit: Int!, $locale: String!) {
    posts: allContentfulSeoPost(
      limit: $limit
      skip: $skip
      sort: {fields: date, order: DESC}
      filter: {slug: {ne: null}, node_locale: {eq: $locale}}
      ) {
      edges {
        node {
          slug
          featuredImage {
            file {
              url
            }
          }
          title
          date
          node_locale
          contentful_id
        }
      }
    }
    fallbackImage: allContentfulSeoPost(
      filter: {title: {ne: null}, node_locale: {eq: "en-US"}}
      ) {
      edges {
        node {
          featuredImage {
            file {
              url
            }
          }
          node_locale
          contentful_id
        }
      }
    }
  }
`;
